import React, { useState } from "react";
import DashboardLayout from "../components/DashboardLayout";
import SearchInput from "../components/Input";
import SortByDropdown from "../components/SortByDropdown";
import SavedSearchDropdown from "../components/SavedSearchDropdown";
import FilterIcon from "../components/FilterIcon";
import { useNavigate } from "react-router-dom";
import CryptoTable from "../components/CryptoTable";
import Sidebar from "../components/Sidebar"; // Import Sidebar component
import TransactionTableBinance from "../components/TransactionTableBinance";
import TransactionTableKucoin from "../components/TransactionTableKucoin";
import AdminLogin from "../pages/Login";

const Dashboard = () => {
  const [activeMenuItem, setActiveMenuItem] = useState("Dashboard");
  const [isAdminLoginVisible, setIsAdminLoginVisible] = useState(false);
  const navigate = useNavigate();
  const [transactionsKucoin, setTransactionsKucoin] = useState([]);
  const [transactionsBinance, setTransactionsBinance] = useState([]);
  const addTransactionToKucoin = (transaction) => {
    setTransactionsKucoin((prevTransactions) => [
      { ...transaction, side: "Buy" }, // Assuming a Buy on Kucoin
      ...prevTransactions,
    ]);
    // Create a corresponding Sell on Binance with the same pair and amount
    const correspondingSellTransaction = {
      ...transaction,
      side: "Sell",
      type: "Limit Order",
    };
    setTransactionsBinance((prevTransactions) => [
      correspondingSellTransaction,
      ...prevTransactions,
    ]);
  };

  const addTransactionToBinance = (transaction) => {
    setTransactionsBinance((prevTransactions) => [
      { ...transaction, side: "Buy" }, // Assuming a Buy on Binance
      ...prevTransactions,
    ]);
    // Create a corresponding Sell on Kucoin with the same pair and amount
    const correspondingSellTransaction = {
      ...transaction,
      side: "Sell",
      type: "Limit Order",
    };
    setTransactionsKucoin((prevTransactions) => [
      correspondingSellTransaction,
      ...prevTransactions,
    ]);
  };

  // Function to handle sidebar menu item click
  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };
  const redirectToLogin = () => {
    navigate("/login");
  };

  return (
    <DashboardLayout>
      {/* Sidebar with a callback to handle menu clicks */}
      <div className="flex min-h-screen">
        <Sidebar onMenuItemClick={handleMenuItemClick} />

        {/* Main content */}
        <div className="flex-grow py-[40px] px-[20px] bg-[#343434]">
          <div className="flex justify-end">
            <button
              type="button"
              className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              onClick={redirectToLogin}
            >
              {isAdminLoginVisible ? "Back" : "Login"}
            </button>
          </div>
          {isAdminLoginVisible && <AdminLogin />}

          {/* Show content based on active menu item */}
          {activeMenuItem === "Cryptocurrencies" ? (
            <>
              {/* Table Section */}
              <CryptoTable />
            </>
          ) : activeMenuItem === "Transaction History KuCoin" ? (
            <TransactionTableKucoin
              transactions={transactionsKucoin}
              addTransaction={addTransactionToKucoin}
            />
          ) : activeMenuItem === "Transaction History Binance" ? (
            <TransactionTableBinance
              transactions={transactionsBinance}
              addTransaction={addTransactionToBinance}
            />
          ) : (
            <div className="text-white">
              <h1 className="text-[22px] leading-[30px] font-bold">
                {activeMenuItem}
              </h1>
              <p className="text-[#CCCCCC] font-light text-[14px] leading-[21px]">
                This is the {activeMenuItem} section. Content coming soon...
              </p>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;

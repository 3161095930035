import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // Handle login form submission
  const handleLogin = (e) => {
    e.preventDefault();

    // Validate credentials
    if (username === "admin" && password === "password123") {
      navigate("/"); // Redirect to Dashboard
    } else {
      alert("Invalid username or password."); // Show error for invalid credentials
    }
  };

  return (
    <div className="flex login-banner justify-center items-center min-h-screen">
      {/* Login form container */}
      <div className="bg_layer w-full max-w-[50%] h-screen flex items-center">
        <h1 className="text-8xl text-[#fff] text-center">
          Access Business Account
        </h1>
      </div>
      <div className="right-con px-[80px] w-full max-w-[50%] bg-[#F5F5F5] h-screen flex flex-col justify-center">
        {/* Header */}
        <h1 className="text-[48px] font-bold text-gray-800">
          Login into admin account
        </h1>
        <p className="text-sm text-gray-500 mt-2">
          Remember to authorize with the correct address.
        </p>

        {/* Form */}
        <form className="mt-6 bg-white rounded-lg p-[40px]" onSubmit={handleLogin}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-700 font-medium mb-2"
            >
              Please Enter Username
            </label>
            <input
              type="text"
              id="username"
              placeholder="Enter your email ID or username"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 font-medium mb-2"
            >
              Please Enter Password
            </label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-teal-500 text-white py-3 rounded-lg hover:bg-teal-600 transition"
          >
            Login
          </button>
        </form>

        {/* Footer */}
        <footer className="text-center text-sm text-gray-400 mt-6">
          Copyright © 2024. All Rights Reserved.
        </footer>
      </div>
    </div>
  );
}

export default Login;
